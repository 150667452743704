.container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .text {
    position: absolute;
    mix-blend-mode: screen;
  }
  
  .blue::after {
    content: "Notre Tiktok";
    position: absolute;
    color: #00def4;
    width: 100%;
    right: -1px;
    top: -2px;
  }
  
  
  .blue::before {
      content: "Notre Tiktok";
      position: absolute;
      color: #ff1b50;
      width: 100%;
      left: -1px;
      bottom: -2px;
    }
  
  .red {
    color: #ff1b50;
    animation: 0.5s infinite linear red-movement;
  }
  